<template>
<Navbar user="0" />
    <section class="contact-info-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box mb-30">
              <div class="icon">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
              </div>
              <h3>Email Here</h3>
              <p>
                <a
                  href="../../cdn-cgi/l/email-protection#59313c353536192b38282c3c773a3634"
                  ><span
                    class="__cf_email__"
                    data-cfemail="08606d646467487a69797d6d266b6765"
                    >[email&#160;protected]</span
                  ></a
                >
              </p>
              <p>
                <a
                  href="../../cdn-cgi/l/email-protection#6f1d0e1e1a0a2f070a030300410c0002"
                  ><span
                    class="__cf_email__"
                    data-cfemail="c1b3a0b0b4a481a9a4adadaeefa2aeac"
                    >[email&#160;protected]</span
                  ></a
                >
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box mb-30">
              <div class="icon">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
              </div>
              <h3>Location Here</h3>
              <p>
                <a href="https://goo.gl/maps/Mii9keyeqXeNH4347" target="_blank"
                  >2750 Quadra Street Victoria Road, New York, USA</a
                >
              </p>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
          >
            <div class="contact-info-box mb-30">
              <div class="icon">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
              </div>
              <h3>Call Here</h3>
              <p><a href="tel:1234567890">+123 456 7890</a></p>
              <p><a href="tel:2414524526">+241 452 4526</a></p>
            </div>
          </div>
        </div>
      </div>
      <div id="particles-js-circle-bubble-2"></div>
    </section>

    <section class="contact-area pb-100">
      <div class="container">
        <div class="section-title">
          <span class="sub-title">Contact Us</span>
          <h2>Drop us Message for any Query</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        <div class="contact-form">
          <form id="contactForm">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="form-control"
                    required
                    data-error="Please enter your name"
                    placeholder="Your Name"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    class="form-control"
                    required
                    data-error="Please enter your email"
                    placeholder="Your Email"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    required
                    data-error="Please enter your number"
                    class="form-control"
                    placeholder="Your Phone"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <input
                    type="text"
                    name="msg_subject"
                    id="msg_subject"
                    class="form-control"
                    required
                    data-error="Please enter your subject"
                    placeholder="Your Subject"
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <textarea
                    name="message"
                    class="form-control"
                    id="message"
                    cols="30"
                    rows="5"
                    required
                    data-error="Write your message"
                    placeholder="Your Message"
                  ></textarea>
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <button type="submit" class="default-btn">
                  <i class="bx bx-paper-plane icon-arrow before"></i
                  ><span class="label">Send Message</span
                  ><i class="bx bx-paper-plane icon-arrow after"></i>
                </button>
                <div id="msgSubmit" class="h3 text-center hidden"></div>
                <div class="clearfix"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div id="particles-js-circle-bubble-3"></div>
      <div class="contact-bg-image">
        <img src="assets/img/map.png" alt="image" />
      </div>
    </section>

    <div id="map">
       <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Chabruma%20Street,%20Ali%20Hassan%20Mwinyi%20Road,%20vipajijobs,%20Dar%20es%20Salaam,%20Tanzania&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
export default {
  components: { Navbar },
  data(){
    return {
      isLoading: true,
      token: localStorage.getItem('user_data'),
      user:{},
      authError: null
    }
  },
  methods:{
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
          this.user = response.data
      }).catch(errors => {
         this.authError = errors.response.data.errors
      })
    },
  },
  created(){
    this.getUser()
  }
}
</script>

<style>

</style>